<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">

        <div class="va-card">
          <div class="row">
            <div class="col-lg-12">
              <div class="dash_card_body pb-0">
                <div class="header_section">
                  <h4 class="menu_header">Gift cards</h4>
                </div>
                <div class="search_section mb-0">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group has-search">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="voucherSearch" @Keyup="searchVoucher()" class="form-control" placeholder="Search by Code">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mr-2" @click="searchVoucher()">
                          <span v-if="searchingType == 'search'"><span v-html="searchspinner"></span></span> Search
                        </button>
                      </div>
                    </div>
                  </div>

                  <!--Status menu start-->
                  <div class="St-menu">
                    <div class="status_btn_group"></div>

                  </div>
                  <!--Status menu End-->
                </div>
              </div>
              <hr>
            </div>
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Voucher Code</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Recipient</th>
                        <th scope="col">Time Used</th>
                        <th scope="col">Action</th>

                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="voucher in vouchers" :key="voucher.id">
                        <td>
                          <span class="title_text">{{voucher.code}} </span>
                        </td>
                        <td>
                          <strong>Amount:</strong> {{voucher.amount}} <br>
                          <strong>Balance:</strong> {{voucher.balance}}
                        </td>
                        <td>
                          <strong>Name:</strong> {{voucher.recipient_first_name + ' ' + voucher.recipient_last_name}} <br>
                          <strong>Email:</strong> {{voucher.recipient_email}} <br>
                          <strong>Phone:</strong> {{voucher.recipient_phone}}
                        </td>
                        <td>
                          {{voucher.num_time_used}}
                        </td>
                        <td>
                          <span v-if="voucherStatus[voucher.status] != 'Void'">
                            <span v-if="voucher.status == 1 || voucher.status == 0">
                              <button @click="veryVoucher(voucher.code)" :disabled="disabled" type="button" class="btn btn_voucher  btn-sm mr-2 mt-1"><span v-if="verifySpinner == voucher.code" v-html="spinner"></span><span v-else>Verify</span> </button>
                            </span>
                            <span v-if="voucher.payment_status == 'COMPLETED' || (voucher.bulk_generated && voucher.recipient_first_name)">
                              <span v-if="!voucher.used">
                                <button type="button" data-toggle="modal" :data-target="'#redeemModal_' + voucher.id" class="btn btn-success btn-sm mr-2 mt-1">Redeem</button>

                                <button type="button" class="btn btn-success btn-sm mr-2 mt-1" @click="print(voucher.code, 'voucher')">Print</button>
                                <button type="button" class="btn btn-success btn-sm mr-2 mt-1" data-toggle="modal" :data-target="'#sendNotification' + voucher.code">Send</button>
                              </span>
                            </span>
                          </span>

                          <!-- redeem modal -->
                          <div class="modal fade" :id="'redeemModal_' + voucher.id" tabindex="-1" role="dialog" aria-labelledby="redeemModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="redeemModalLabel">Redeem Voucher - <span class='voucher'>{{ voucher.code }}</span></h5>
                                  <button type="button" :id="'redeem_close_btn_' + voucher.code" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <form @submit.prevent="redeemVoucher(voucher.code)">
                                  <div class="modal-body" style="padding:21px !important">
                                    <input type="hidden" name="_token">
                                    <strong>Voucher amount: </strong><span class='voucher_amount'>{{ formatPrice(voucher.amount) }}</span><br>
                                    <strong>Available balance: </strong><span class='voucher_balance'>{{ formatPrice(voucher.balance) }}</span>
                                    <p></p>
                                    <div class="form-group">
                                      <label class="control-label">Amount</label>
                                      <div>
                                        <input type="text" id="redeem_amount" class="form-control input-lg" v-model="redeemAmount">
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label class="control-label">Check Id</label>
                                      <div>
                                        <input type="text" id="redeeem_checkid" class="form-control input-lg" name="check_id" v-model="checkID">
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label for="redeen_location">Select Branch</label>
                                      <select class="form-control" v-model="storeId" id="redeem_location">
                                        <option v-for="store in stores" :key="store.id" :value="store.id">
                                          {{ store.branch_name }}</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="submit" class="btn btn-primary redeem_submit"><span v-if="redeemingVoucher == voucher.code"><i class='fa fa-spinner fa-spin '></i></span> Redeem</button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          <!-- end redeem mail -->

                          <!-- send notification mail modal -->
                          <div class="modal fade" :id="'sendNotification' + voucher.code" tabindex="-1" role="dialog" aria-labelledby="voidModalLabel" aria-hidden="true">
                            <div class="modal-dialog  " role="document">
                              <div class="modal-content">
                                <div class="modal-header">

                                  <h5 class="modal-title" id="voidModalLabel">Send Notification for Voucher:
                                    <strong>({{ voucher.code }})</strong></h5>
                                  <button type="button" :id="'sendN_close_btn_' + voucher.code" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <form @submit.prevent="sendNotification(voucher.code, voucher.id)">
                                  <div class="modal-body" style="padding:21px !important">
                                    <div class="form-group">
                                      <label class="control-label">Phone</label>
                                      <div>
                                        <input type="text" id="redeem_amount" class="form-control input-lg " v-model="notificationPhone[voucher.id]">
                                      </div>

                                    </div>

                                    <div class="form-group">
                                      <label class="control-label">Email</label>
                                      <div>
                                        <input type="text" id="redeem_amount" class="form-control input-lg " v-model="notificationEmail[voucher.id]" @blur="v$.firstName.$touch">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="submit" class="btn btn-primary void_btn"><span v-if="sendingNotifications == voucher.code"><i class='fa fa-spinner fa-spin '></i></span>Send Notification</button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>

                        </td>
                      </tr>

                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="6">
                          <div id="pagination" v-if="totalPages > 1">
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                  <option :value="100">100</option>
                                </select>
                              </li>

                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />

                          </div>

                        </td>
                      </tr>
                    </tfoot>
                  </table>

                </div>
                <div v-if="loading" class="loading-cover">
                  <div class="loader"> </div>
                </div>

              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import Pagination from '@/pages/pagination/pagination.vue';
//   import Multiselect from '@vueform/multiselect';
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
    components: {
        Pagination,
        //   Multiselect
    },
    setup() {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            firstName: { required },
            lastName: { required },
            phone: { required },
            email: { required, email },
        }
    },
    data() {
        return {
            start: '',
            end: '',
            vouchers: [],
            voucherStatus: ['Pending', 'Active', 'Void', 'Used'],
            verifySpinner: 0,
            spinner: "<i class='fa fa-spinner fa-spin '></i>",
            loading: false,
            page: 1,
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: 10,
            debounce: null,
            voucherSearch: '',
            disabled: false,
            errors: false,
            errorMsg: [],
            storeId: '',
            checkID: '',
            sendingNotifications: null,
            redeemingVoucher: null,
            notificationPhone: [],
            notificationEmail: [],
            stores: [],

        }
    },
    beforeMount: async function () {
        this.getVoucherItems();
        this.getBranches();
    },

    methods: {

        searchVoucher() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getVoucherItems();
            }, 1000)
        },
        async veryVoucher(code) {
            //
            this.disabled = true;
            this.verifySpinner = code;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/verify`;
            const payload = {
                "code": code,
                "merchant_id": this.merchantID,
            };
            this.axios.post(url, payload, config).then((response) => {
                if (response.status === 200) {
                    toast.success(response.data.data.message + " " + "code: " + code);
                }
            }).catch((err) => {
                if (err.response.status === 400) {
                    this.errorMsg = err.response;
                    toast.error(err.response.data.errors.message)
                } else if (err.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }

            }).finally(() => {
                this.verifySpinner = 0;
                this.disabled = false;
            });
        },
        redeemVoucher(code) {
            this.errors = false;
            this.redeemingVoucher = code;
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                }
            };
            const url = `${this.dashBaseUrl}/redeem`
            const payload = {
                'code': code,
                'location_id': this.storeId,
                'check_id': this.checkID,
                'amount': this.redeemAmount,
                'merchant_id': this.merchantID,
            };
            this.axios.post(url, payload, config).then((response) => {
                if (response.status === 200) {
                    document.getElementById('redeem_close_btn_' + code).click();
                    toast.success('voucher was redeemed successfully');
                    this.getVoucherItems();
                }
            }).catch((error) => {
                document.getElementById('redeem_close_btn_' + code).click();
                if (error.response.status === 400) {
                    this.errors = true;
                    this.errorMsg = error.response.data.errors
                } else if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            }).finally(() => {
                this.redeemingVoucher = null;
            });
        },
        sendNotification(code, voucherid) {
            this.sendingNotifications = code;
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                }
            };
            const payload = {
                'code': code,
                'phone': this.notificationPhone[voucherid],
                'email': this.notificationEmail[voucherid],
                'merchant_id': this.merchantID,
            }
            const url = `${this.dashBaseUrl}/send-notification`;
            this.axios.post(url, payload, config).then((response) => {
                if (response.status === 200) {
                    toast.success('notification sent successfully');
                }
            }).catch((error) => {
                console.log('err', error);
            }).finally(() => {
                this.sendingNotifications = null;
                const closeBtn = document.getElementById('sendN_close_btn_' + code);
                if (closeBtn) {
                    closeBtn.click();
                }
            })
        },
        async getBranches() {
            const url = `${this.baseUrl}/branch?merchant_id=${this.merchantID}`;
            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                if (response.status === 200) {
                    this.stores = response.data.data;
                }
            }).catch((err) => {
                console.log('error', err);
            })

        },
        async getVoucherItems() {
            try {
                this.loading = true;
                let url = `${this.dashBaseUrl}/gift-voucher-items/${this.merchantID}`;
                url = new URL(url);
                if (this.voucherSearch != '') {
                    url.searchParams.set('code', this.voucherSearch)
                }
                url.searchParams.set('size', this.recordsPerPage);
                url.searchParams.set('page', this.page);
                const response = await this.axios.get(url, this.apiRequestConfig());
                if (response.status == 200) {
                    this.loading = false;
                    this.vouchers = response.data.data.data.data;
                    this.totalPages = Math.ceil(response.data.data.data.total / this.recordsPerPage)
                    this.totalRecords = response.data.data.data.total;
                }

            } catch (error) {
                this.loading = false;
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            }
        },

        onPageChange(page) {
            this.page = page
            this.getVoucherItems();
        },
        onChangeRecordsPerPage() {
            this.getVoucherItems();
            // ds
        }
    },
    mixins: [ReusableFunctionMixins, ReusableDataMixins]
}
</script>
